import { cn } from '../../utils/cn';

export function LogicIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      className={cn('w-4 h-4 fill-current', props.className)}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 256 256'
    >
      <path d='M232,64a32,32,0,1,0-40,31v17a8,8,0,0,1-8,8H96a23.84,23.84,0,0,0-8,1.38V95a32,32,0,1,0-16,0v66a32,32,0,1,0,16,0V144a8,8,0,0,1,8-8h88a24,24,0,0,0,24-24V95A32.06,32.06,0,0,0,232,64ZM64,64A16,16,0,1,1,80,80,16,16,0,0,1,64,64ZM96,192a16,16,0,1,1-16-16A16,16,0,0,1,96,192ZM200,80a16,16,0,1,1,16-16A16,16,0,0,1,200,80Z'></path>
    </svg>
  );
}
